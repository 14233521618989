import { useEffect, useState } from 'react';
import { ENDPOINTS } from '../../endpoints';
import { API } from '../../services/api';
import { ICategories, StatusType } from './home.types';

export function useHome() {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);

  const handleCheckbox = (value: StatusType) => {
    setStatusFilter((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }
      setStatusFilter([...prevState, value]);
    });
  };

  const fetchCategory = async () => {
    try {
      setIsLoading(true);
      const auth = await API.post(ENDPOINTS.auth, {
        returnSecureToken: true,
        email: 'varronche.taking@pedepronto.com.br',
        password: '123456',
      });
      const response = await API.get(ENDPOINTS.menu.getCategory, {
        headers: { Http_Authorization: `Bearer ${auth.data.idToken}` },
      });
      const { data } = response;
      setCategories(data.content);
    } catch (e) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return {
    isLoading,
    categories,
    statusFilter,
    handleCheckbox,
  };
}
