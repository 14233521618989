export const getColor = (isLastItem: boolean) => {
  const color = isLastItem ? '#6A2CB1' : '#9B96A0';
  return color;
};

export const getWeight = (isLastItem: boolean) => {
  const weight = isLastItem ? 'semi-bold' : 'regular';
  return weight;
};

export const getMargin = (isLastItem: boolean) => {
  const margin = isLastItem ? 'ml-1' : 'mr-1';
  return margin;
};
