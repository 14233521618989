import styled, { css } from 'styled-components';

export const ItemContent = styled.div`
  background: #fff;
  width: 100%;
  min-height: 96px;
  padding: 0 0px 15px 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemDescriptionBox = styled.div`
  display: flex;
`;
export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 232px;
`;

export const PriceBox = styled.div``;

export const DetailsBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ComplementButton = styled.button<any>`
  ${({ shouldShowComplement, disabled }) => css`
    padding: 8px;
    height: 32px;
    border-radius: 50px;
    background: ${shouldShowComplement ? '#6A2CB1' : '#fff'};
    border: 1px solid #6a2cb1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    cursor: ${disabled ? 'not-allowed;' : 'pointer'};
    opacity: ${disabled ? 0.4 : 1};
    span {
      color: ${shouldShowComplement ? '#fff' : '#6A2CB1'};
    }
  `}
`;
