import { Breadcumb, Loading, Typography } from '../../components';
import { Category, Checkbox } from './components';

import { Container, Content, FilterBox, TextBox } from './home.styles';
import { useHome } from './home.hook';
import { breadcumbItems } from './home.utils';
import { Status } from './home.types';

export function Home() {
  const { isLoading, categories, statusFilter, handleCheckbox } = useHome();

  return (
    <>
      {isLoading && <Loading />}
      <Container>
        <Content>
          <Typography fontSize={24} weight='semi-bold' color='#2D2931'>
            Cárdapio
          </Typography>
          <Breadcumb className='mt-1 mb-3' items={breadcumbItems} />
          <TextBox className='mb-3'>
            <Typography color='#2D2931' fontSize={16} lineHeight={24}>
              Utilize este espaço para visualizar os produtos do seu cardápio.
              Veja o que está disponível e indisponível e faça a gestão de seus
              itens.
            </Typography>
            <Typography
              color='#6A2CB1'
              weight='bold'
              fontSize={16}
              lineHeight={24}
              className='ml-1'
            >
              Vamos Começar ? :)
            </Typography>
          </TextBox>

          <FilterBox>
            <Typography color='#61596C'>Filtrar: </Typography>
            <Checkbox
              id='available'
              label='Disponíveis'
              htmlFor='available'
              className='ml-3'
              checked={statusFilter?.includes(Status.AVAILABLE)}
              onChange={() => handleCheckbox(Status.AVAILABLE)}
            />
            <Checkbox
              id='unavailable'
              label='Indisponíveis'
              htmlFor='unavailable'
              className='ml-3'
              checked={statusFilter?.includes(Status.UNAVAILABLE)}
              onChange={() => handleCheckbox(Status.UNAVAILABLE)}
            />
          </FilterBox>

          {categories?.map((category) => {
            return (
              <Category
                key={category?.id}
                imgPath={category?.image?.path}
                title={category?.name}
                status={category.status}
                itemsId={category?.itemOfferId}
                filterValue={statusFilter}
              />
            );
          })}
        </Content>
      </Container>
    </>
  );
}
