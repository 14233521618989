import React from 'react';
import { Checkbox, Loading, Typography, Divider } from '../../../../components';
import { formatBRL } from '../../../../utils';
import { Status } from '../../home.types';
import { Complement } from '../Complement';
import { useCategoryItem } from './category-item.hook';
import {
  ComplementButton,
  DescriptionBox,
  DetailsBox,
  ItemDescriptionBox,
  ItemContent,
  PriceBox,
  ItemBox,
} from './category-item.styles';
import { ICategoryItem } from './category-item.types';

export function CategoryItem({
  item,
  price,
  complementIds,
  handleFilter,
}: ICategoryItem) {
  const {
    complement,
    shouldShowComplement,
    fetchCategoryItemOptions,
    isLoading,
    handleOpenCloseComplement,
    shouldRenderComplements,
    handleActiveAndDesactiveItem,
    available,
  } = useCategoryItem(item.status, handleFilter);

  return (
    <React.Fragment key={item.id}>
      {isLoading && <Loading />}
      <ItemContent className='mt-4'>
        <ItemBox>
          <ItemDescriptionBox>
            <img src={item?.image?.URL} width={64} height={64} />
            <DescriptionBox className='ml-3'>
              <Typography
                color='#3F3A44'
                fontSize={16}
                weight='semi-bold'
                lineHeight={24}
              >
                {item.name}
              </Typography>
              <Typography color='#7F7885' lineHeight={16} className='mt-1'>
                {item.description}
              </Typography>
            </DescriptionBox>
          </ItemDescriptionBox>
          <PriceBox>
            <Typography
              color='#5D3397'
              lineHeight={16}
              weight='semi-bold'
              fontSize={16}
            >
              {formatBRL(price.value)}
            </Typography>
          </PriceBox>
        </ItemBox>
        <DetailsBox className='ml-5'>
          <ComplementButton
            className='ml-5'
            shouldShowComplement={shouldShowComplement}
            onClick={() => {
              handleOpenCloseComplement();
              fetchCategoryItemOptions(complementIds);
            }}
            disabled={!complementIds.length}
          >
            <Typography
              color='#6A2CB1'
              weight='bold'
              fontSize={10}
              lineHeight={20}
            >
              {shouldRenderComplements
                ? 'ESCONDER COMPLEMENTOS'
                : 'VER COMPLEMENTOS'}
            </Typography>
          </ComplementButton>
          <Checkbox
            className='mr-5'
            colorChecked='#87E6BF'
            colorUnchecked='#EA6364'
            checked={available === Status.AVAILABLE}
            onChange={() => handleActiveAndDesactiveItem(item.id, available)}
          />
        </DetailsBox>
      </ItemContent>
      {!shouldRenderComplements && <Divider borderWidth={1} color='#E2E1E3' />}
      {shouldRenderComplements &&
        complement?.map((option) => {
          return (
            <Complement
              key={option.id}
              complement={option.name}
              item={option.options}
            />
          );
        })}
    </React.Fragment>
  );
}
