import { Container, Line } from './divider.styles';
import { IDivider } from './divider.types';

export function Divider({ borderWidth, color }: IDivider) {
  const border = `${borderWidth}px solid ${color}`;

  return (
    <Container>
      <Line border={border} />
    </Container>
  );
}
