import { Box, CategoryTitle, Container, Content } from './category.styles';
import { Typography, Loading, Divider } from '../../../../components';
import { Icon } from '../../../../assets/icons';
import { ICategoryProps } from './category.types';
import React from 'react';
import { useCategory } from './category.hook';
import { CategoryItem } from '../CategoryItem';

export function Category(props: ICategoryProps) {
  const { title, itemsId = [], filterValue } = props;

  const {
    handleOpenCloseCategoryItems,
    fetchCategoryItems,
    items,
    isLoading,
    shouldShowCategoryItems,
    handleFilter,
  } = useCategory(filterValue, itemsId);

  return (
    <>
      {isLoading && <Loading />}
      <Container className='mt-4'>
        <Content>
          <Box>
            <CategoryTitle className='ml-3'>
              <Typography
                color='#6A2CB1'
                fontSize={20}
                lineHeight={32}
                weight='bold'
              >
                {title}
              </Typography>
            </CategoryTitle>
          </Box>
          <Box style={{ paddingRight: '50px' }}>
            <button
              onClick={() => {
                handleOpenCloseCategoryItems();
                fetchCategoryItems();
              }}
            >
              <Icon name='arrow-down' width={11} height={8} />
            </button>
          </Box>
        </Content>
        {shouldShowCategoryItems && <Divider borderWidth={1} color='#6a2cb1' />}
        {shouldShowCategoryItems &&
          items?.map((item) => (
            <CategoryItem
              key={item.id}
              item={item.item}
              price={item.price}
              complementIds={item.optionGroupsId}
              handleFilter={handleFilter}
            />
          ))}
      </Container>
    </>
  );
}
