import styled, { css } from 'styled-components';

export const CheckboxInput = styled.input<any>`
  ${({ colorChecked, colorUnchecked, disabled }) => css`
    position: relative;
    height: 12px;
    width: 40px;
    outline: none;
    background: ${disabled ? '#D4D2D5' : colorUnchecked};
    -webkit-appearance: none;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    cursor: pointer;
    opacity: ${disabled ? 0.1 : 1};

    &:checked {
      background: ${disabled ? '#D4D2D5' : colorChecked};
    }

    &:before {
      height: 22px;
      width: 22px;
      left: -2px;
      top: -6px;
      position: absolute;
      content: '';
      background: #fff;
      border-radius: 50px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      transform: scale(0.85);
      transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      border: 1px solid ${colorUnchecked};
    }

    &:checked:before {
      left: 28px;
      top: -6px;
      border: 1px solid ${colorChecked};
    }
  `}
`;

// import styled from 'styled-components';

// export const Container = styled.div`
//   height: 80px;
//   width: 270px;
//   background: #fff;
//   display: flex;
//   align-items: center;
//   padding: 20px;
//   justify-content: space-between;
// `;

// export const CheckboxInput = styled.input`
//   position: relative;
//   height: 12px;
//   width: 40px;
//   outline: none;
//   background: #e6e6e6;
//   -webkit-appearance: none;
//   border-radius: 50px;
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

//   &:checked {
//     background: #664aff;
//   }

//   &:before {
//     height: 23px;
//     width: 23px;
//     left: -2px;
//     top: -5px;
//     position: absolute;
//     content: '';
//     background: #fff;
//     border-radius: 50px;
//     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//     transform: scale(0.85);
//     transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
//   }

//   &:checked:before {
//     left: 28px;
//     top: -5px;
//   }
// `;
