export interface ICategories {
  id: string;
  name: string;
  index: number;
  description: string;
  image: any;
  externalCode: string;
  status: string;
  availabilityId: string[];
  itemOfferId: string[];
}

export enum Status {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type StatusType = keyof typeof Status;
