import { Status, StatusType } from '../../home.types';

export const getPayload = (status: StatusType) => {
  const payload = { status: Status.AVAILABLE };

  if (status) {
    payload.status =
      status === Status.AVAILABLE ? Status.UNAVAILABLE : Status.AVAILABLE;
  }

  return payload;
};
