import { css } from 'styled-components';
import { fontWeights } from '../../theme';
import { GetTypographyStyles } from './typography.types';

export const getTypographyStyles: GetTypographyStyles = (params) => {
  const { fontSize, weight, align, color, lineHeight } = params;
  return css`
    font-size: ${fontSize}px;
    font-weight: ${fontWeights[weight]};
    text-align: ${align};
    color: ${color};
    ${lineHeight ? `line-height: ${lineHeight}px;` : ''}
  `;
};
