import { css } from 'styled-components';
import { ButtonVariant, GetButtonStyles } from './button.types';

export const getButtonStyles: GetButtonStyles = (params) => {
  const { variant, fullWidth } = params;

  return css`
    ${fullWidth ? 'width: 100%;' : ''}

    ${variant === ButtonVariant.contained
      ? 'background-color: #6A2CB1; color: #fff;'
      : 'background-color: #fff; color: #6A2CB1'}
  `;
};
