import { ICheckboxProps } from './checkbox.types';
import { Container, Input } from './checkbox.styles';
import { Typography } from '../../../../components';

export function Checkbox({
  className,
  htmlFor,
  label,
  ...rest
}: ICheckboxProps) {
  return (
    <Container className={className}>
      <Input type='checkbox' {...rest} />
      <Typography as='label' htmlFor={htmlFor} color='#61596C'>
        {label}
      </Typography>
    </Container>
  );
}
