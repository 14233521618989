import { icons, IconProps } from './types';

export const Icon = (props: IconProps) => {
  const { name, className, color, width, height } = props;
  return (
    <svg width={width} height={height} className={className}>
      <path d={icons[name]} fill={color || '#000'} />
    </svg>
  );
};
