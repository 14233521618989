import { useState, useCallback } from 'react';
import { ENDPOINTS } from '../../../../endpoints';
import { API } from '../../../../services/api';
import { ICategoriesItems, Item } from './category.types';

export function useCategory(filterValue: string[], itemsId: string[]) {
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [categoryItems, setCategoryItems] = useState<ICategoriesItems[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPayload = useCallback(() => {
    const payload = {
      itemOffersIds: itemsId,
    };

    return payload;
  }, [itemsId]);

  const fetchCategoryItems = useCallback(async () => {
    if (isOpenCategory) return;

    try {
      setIsLoading(true);
      const auth = await API.post(ENDPOINTS.auth, {
        returnSecureToken: true,
        email: 'varronche.taking@pedepronto.com.br',
        password: '123456',
      });

      const payload = getPayload();
      const response = await API.post(ENDPOINTS.menu.getCategoryItem, payload, {
        headers: {
          'Content-Type': 'application/json',
          Http_Authorization: `Bearer ${auth.data.idToken}`,
        },
      });
      const { data } = response;
      setCategoryItems(data?.content);
    } catch (e) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [getPayload, isOpenCategory]);

  const handleFilter = (item: Item) => {
    const index = categoryItems.findIndex(
      (categoryItem) => categoryItem.item.id === item.id
    );
    const serializedCategoryItems = categoryItems.map((categoryItem) => {
      if (categoryItem.item.id === item.id) {
        return {
          ...categoryItems[index],
          item: item,
        };
      }
      return categoryItem;
    });
    setCategoryItems(serializedCategoryItems);
  };

  const handleOpenCloseCategoryItems = () => {
    setIsOpenCategory((prevState) => !prevState);
  };

  const shouldShowCategoryItems = isOpenCategory && !isLoading;

  const shouldShowAllItems =
    filterValue.length === 0 || filterValue.length === 2;

  const items = shouldShowAllItems
    ? categoryItems
    : categoryItems.filter((item) => item.item.status === filterValue[0]);

  return {
    handleOpenCloseCategoryItems,
    fetchCategoryItems,
    items,
    isLoading,
    shouldShowCategoryItems,
    handleFilter,
  };
}
