import { Typography } from '../../../../components';
import { formatBRL } from '../../../../utils';
import {
  Content,
  Header,
  Price,
  Row,
  ComplementItem,
  HeaderTitle,
} from './complement.styles';

export function Complement({ complement, item }: any) {
  return (
    <Content>
      <div style={{ marginLeft: '155px', marginRight: '56px' }}>
        <Header>
          <HeaderTitle>
            <Typography
              fontSize={16}
              weight='semi-bold'
              lineHeight={24}
              color='#35007D'
              className='ml-2'
            >
              {complement}
            </Typography>
          </HeaderTitle>
        </Header>
        {item?.map((option) => {
          return (
            <Row key={option.item.id}>
              <ComplementItem>
                <Typography color='#49454F'>{option.item.name}</Typography>
              </ComplementItem>
              <Price>
                <Typography color='#49454F'>
                  {formatBRL(option.price.value)}
                </Typography>
              </Price>
            </Row>
          );
        })}
      </div>
    </Content>
  );
}
