import styled from 'styled-components';
import { ButtonStyleProps } from './button.types';

export const StyledButton = styled.button<ButtonStyleProps>`
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #6a2cb1;
  font-size: 14px;
  ${({ styles }) => styles}
`;
