import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Line = styled.hr<any>`
  ${({ border }) => css`
    border: ${border};
  `}
`;
