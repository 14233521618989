import styled from 'styled-components';

export const SelectBox = styled.div``;

export const StyledSelect = styled.select`
  width: 100%;
  height: 36px;
  cursor: pointer;

  option {
    cursor: pointer !important;
  }
`;
