import styled from 'styled-components';

export const Container = styled.div`
  width: 1104px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
`;

export const Content = styled.div`
  height: 72px;
  display: flex;
  justify-content: space-between;
`;

export const CategoryTitle = styled.div``;

export const StatusCard = styled.div`
  width: 142px;
  height: 32px;
  border: 1px solid #f1f0f1;
  border-radius: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;
