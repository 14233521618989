export const ENDPOINTS = {
  auth: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCMohC3T6WsY9T3d87HZIF12fNgW3mOK1c',
  menu: {
    getCategory: '/v1/menu-manager/category',
    getCategoryItem:
      '/v1/menu-manager/item-offer/filter?page=0&size=15&sort=index,ASC',
    getCategoryItemOptions: '/v1/menu-manager/option-group/all',
    updateAvailable: (id: string) => `/v1/menu-manager/item/${id}`,
  },
};
