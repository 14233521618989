import { Typography } from './typography.styles';
import { TypographyProps } from './typography.types';
import { getTypographyStyles } from './typography.utils';

export const TypographyComponent = (props: TypographyProps) => {
  const {
    children,
    fontSize = 14,
    weight = 'regular',
    align = 'start',
    color,
    lineHeight,
    className,
    htmlFor,
    as = 'span',
  } = props;

  const params = { fontSize, weight, align, color, lineHeight };
  const styles = getTypographyStyles(params);

  return (
    <Typography styles={styles} as={as} className={className} htmlFor={htmlFor}>
      {children}
    </Typography>
  );
};
