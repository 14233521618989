import { ComponentPropsWithoutRef } from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';
import { IconType } from '../../assets/icons/types';

export enum ButtonVariant {
  contained = 'contained',
  outlined = 'outlined',
}

type ButtonVariantTypes = keyof typeof ButtonVariant;

export type IButtonProps = ComponentPropsWithoutRef<'button'> & {
  variant?: ButtonVariantTypes;
  fullWidth?: boolean;
  className?: string;
  startIcon?: IconType;
  endIcon?: IconType;
};

export type ButtonStyleProps = {
  styles: FlattenSimpleInterpolation;
};

export type GetButtonStyles = (
  params: IButtonProps
) => FlattenSimpleInterpolation;
