import React from 'react';
import { Typography } from '../Typography';
import { Content } from './breadcumb.styles';
import { getColor, getMargin, getWeight } from './breadcumb.utils';
import { Icon } from '../../assets/icons';
import { IBreadcumbProps } from './breadcumb.types';

export const BreadcrumbComponent = (props: IBreadcumbProps) => {
  const { className = '', items } = props;

  return (
    <Content className={className}>
      {items.map((breadcumbItem, index) => {
        const isLastItem = items.length === index + 1;
        return (
          <React.Fragment key={breadcumbItem}>
            <Typography
              color={getColor(isLastItem)}
              weight={getWeight(isLastItem)}
              align='start'
              fontSize={10}
              className={getMargin(isLastItem)}
            >
              {breadcumbItem}
            </Typography>
            {isLastItem ? null : (
              <Icon name='arrow-right' width={6} height={8} color='#9B96A0' />
            )}
          </React.Fragment>
        );
      })}
    </Content>
  );
};
