import { useEffect, useState } from 'react';
import { ENDPOINTS } from '../../../../endpoints';
import { API } from '../../../../services/api';
import { StatusType } from '../../home.types';
import { Item } from '../Category/category.types';
import { IComplement } from './category-item.types';
import { getPayload } from './category-item.utils';

export function useCategoryItem(
  status: StatusType,
  handleFilter: (item: Item) => void
) {
  const [shouldShowComplement, setShouldShowComplement] = useState(false);
  const [complement, setComplements] = useState<IComplement[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [available, setAvailable] = useState<StatusType>('UNAVAILABLE');

  const fetchCategoryItemOptions = async (complementIds: string[]) => {
    if (shouldShowComplement) return;

    try {
      setIsLoading(true);

      const auth = await API.post(ENDPOINTS.auth, {
        returnSecureToken: true,
        email: 'varronche.taking@pedepronto.com.br',
        password: '123456',
      });

      const response = await API.post(
        ENDPOINTS.menu.getCategoryItemOptions,
        complementIds,
        {
          headers: {
            'Content-Type': 'application/json',
            Http_Authorization: `Bearer ${auth.data.idToken}`,
          },
        }
      );
      const { data } = response;
      setComplements(data?.content);
    } catch (e) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const handleActiveAndDesactiveItem = async (
    id: string,
    status: StatusType
  ) => {
    try {
      setIsLoading(true);

      const auth = await API.post(ENDPOINTS.auth, {
        returnSecureToken: true,
        email: 'varronche.taking@pedepronto.com.br',
        password: '123456',
      });

      const payload = getPayload(status);
      const { data } = await API.patch(
        ENDPOINTS.menu.updateAvailable(id),
        payload,
        {
          headers: {
            Http_Authorization: `Bearer ${auth.data.idToken}`,
          },
        }
      );
      setAvailable(data.status);
      console.log('here');
      handleFilter(data);
      console.log('aqiui');
    } catch (e) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemAvailable = (status: StatusType) => {
    setAvailable(status);
  };

  useEffect(() => {
    handleItemAvailable(status);
  }, [status]);

  const handleOpenCloseComplement = () => {
    setShouldShowComplement((prevState) => !prevState);
  };

  const shouldRenderComplements = shouldShowComplement && !isLoading;

  return {
    complement,
    shouldShowComplement,
    fetchCategoryItemOptions,
    isLoading,
    handleOpenCloseComplement,
    shouldRenderComplements,
    handleActiveAndDesactiveItem,
    available,
  };
}
