import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 32px 56px;
  background: #fbf9fc;
`;

export const Content = styled.div``;

export const TextBox = styled.div`
  width: 728px;
`;

export const SelectBox = styled.div`
  width: 352px;
`;

export const FilterBox = styled.div`
  display: flex;
  margin-top: 40px;
`;
