import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading = () => {
  return (
    <Backdrop sx={{ color: '#35007d' }} open>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
