import styled from 'styled-components';

export const Content = styled.div``;

export const Header = styled.div`
  height: 56px;
  background: #f9f9f9;
  padding-left: 30px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div``;

export const Row = styled.div`
  padding-left: 56px;
  display: flex;
  align-items: center;
  height: 56px;
`;

export const ComplementItem = styled.div`
  display: flex;
  align-items: center;
  width: 310px;
`;

export const Price = styled.div``;
